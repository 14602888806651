import { animebrandSeries } from '@/api'
export const statusOption = [//是否启用
    {
        value: '-1',
        label: "全部",
    },
    {
        value: 1,
        label: "禁用",
    },
    {
        value: 2,
        label: "启用",
    },
]


export const statusDetail = [//详情是否启用
    {
        value: 0,
        label: "禁用",
    },
    {
        value: 1,
        label: "启用",
    },
]



export const groundingStatu = [//上架下架
    {
        value: 0,
        label: "下架",
    },
    {
        value: 1,
        label: "上架",
    },
]

// 体育上架
export const sportsStatus = [//上架下架
    {
        value: 'ALL',
        label: "全部",
    },
    {
        value: 0,
        label: "下架",
    },
    {
        value: 1,
        label: "上架",
    },
]